<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-actions"></div>
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
              ></a-select>
            </a-form-item>
            <a-form-item label="设备编号">
              <a-input
                class="form-control"
                v-decorator="['equipmentId']"
                placeholder="请输入设备id"
                allowClear
              ></a-input>
            </a-form-item>
            <a-form-item label="日期">
              <a-date-picker v-decorator="['date']" />
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList } from '@/service/getData'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '项目编码',
        width: '6%',
        align: 'center',
        dataIndex: 'projectCode',
      },
      {
        title: '设备编号',
        width: '6%',
        align: 'center',
        dataIndex: 'equipmentId',
      },
      {
        title: '批次时间',
        width: '6%',
        align: 'center',
        dataIndex: 'batchTime',
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      projectList: [],
      leaderList: [],
    }
  },
  computed: {
    typesMap() {
      return {
        type: [
          { value: 0, label: '正常考勤' },
          { value: 1, label: '加班考勤' },
        ],
        sendOa: [
          { value: 0, label: '未推送' },
          { value: 1, label: '已推送' },
        ],
      }
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.formatParams(this.getPageParams(_pageNo, _pageSize), (key, value) => {
          if (key === 'date') {
            return { key, value: value ? value.format('YYYY-MM-DD') : '' }
          }
          return { key, value }
        })
        const res = await this.$axios.post('/attendance/web/device/status/list', {
          ...params,
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
  },
}
</script>

<style lang="less" scoped></style>
